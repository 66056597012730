import { Router } from "preact-router";
import style from './style.scss';
import { h } from "preact";

// Code-splitting is automated for `routes` directory
import Home from "../routes/home";
import Header from "./header";

const App = () => (
  <div id="app" class={style.app}>
    <Header />
    <Home />
  </div>
);

export default App;