import { useEffect, useRef } from 'preact/hooks';
import { Link } from 'preact-router/match';
import style from './style.scss';
import { h } from 'preact';

function Header() {
  function goHome() {
    window.location.pathname = "";
  }

  function goContact() {
    window.scrollTo(0, 1000000)
  }

  return <header class={style.header}>
		<h1>Cutler</h1>
		<nav>
			<a onClick={goHome} activeClassName={style.active}>Home</a>
			<a onClick={goContact} activeClassName={style.active}>Contact</a>
		</nav>
	</header>
}

export default Header;